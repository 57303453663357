.text-img {
    margin-top: 60px;
    position: relative;
    .title {
        margin-bottom: 30px;
    }
}

.text-img__wrapper {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    .text {
        max-width: 635px;
        p {
            font-weight: 300;
            font-size: 24px;
            line-height: 150%;
            color: #262626;
            margin-bottom: 30px;
        }
    }
    .img {
        max-width: 400px;
        width: 400px;
        img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
    .img-map {
        background: #1c75bb;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 314px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            width: unset;
        }
    }
}

.text-img--v2 {
    padding: 80px 0;
    margin-top: 100px;
    .text {
        max-width: 499px;
        p {
            text-align: left;
        }
        b {
            font-weight: 700;
        }
    }
    .img {
        max-width: 499px;
    }
}
.text-img__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.map {
    .text {
        p {
            font-weight: 300;
            font-size: 24px;
            line-height: 150%;
            color: #262626;
        }
    }
}

.markers-wrapper {
    margin: 30px auto 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    max-width: 816px;
    gap: 40px;
}
.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
    p {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        text-transform: uppercase;
        color: #e67f31;
    }
}
