* {
    font-family: "Roboto", sans-serif;
}

body {
    &.scroll-hide {
        overflow: hidden;
    }
}

section {
    background: #ffffff;
    z-index: 1;
}

.title {
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    color: #1c75bb;
}

.text-center {
    text-align: center;
}

.container {
    max-width: 1090px;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
}

.page {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.btn {
    background: #e67f31;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    width: 394px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease-in-out;
    @media (any-hover: hover) {
        &:hover {
            background: #d56c1b;
        }
    }
}
