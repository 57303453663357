.header {
    background: #ffffff;
    padding: 22px 0;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.logo {
    width: 150px;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}

.nav {
    margin-left: auto;
    margin-right: 70px;
    ul {
        display: flex;
        gap: 90px;
        a {
            font-weight: 700;
            font-size: 18px;
            line-height: 150%;
            text-transform: uppercase;
            color: #262626;
            @media (any-hover: hover) {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.inst {
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}
