.footer {
    background: #262626;
    padding: 40px 0 60px;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    iframe {
        width: 394px;
        height: 174px;
        border: none;
    }
}

.footer__left {
    max-width: 287px;
    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 26px;
    }
    p {
        font-weight: 300;
        font-size: 18px;
        line-height: 250%;
        color: #ffffff;
    }
}
.phone {
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 20px;
    width: fit-content;
    margin-top: 20px;
    @media (any-hover: hover) {
        &:hover {
            text-decoration: underline;
        }
    }
    &::before {
        content: "";
        background-image: url("../../img/Phone.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 24px;
        height: 24px;
    }
}

.footer__right {
    display: flex;
    flex-direction: column;
    a {
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        color: #ffffff;
        display: block;
        margin-top: 20px;
        @media (any-hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }
        &:first-child {
            margin-top: auto;
        }
    }
}
