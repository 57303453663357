.advantages {
    margin-top: 100px;
}
.container {
}

.advantages__text {
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    color: #262626;
    margin: 30px auto 0 auto;
    max-width: 816px;
}
.advantages__wrapper {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 70px;
}
.advantages-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    .icon {
        background: #1c75bb;
        border-radius: 10px;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
    .text {
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #262626;
    }
}

.advantages {
}
.tabs {
}
.container {
}
.title {
}
.text-center {
}
.advantages__text {
}
.tabs__wrapper {
}
.tabs-wrapper {
}
.tab-link-wrapper {
    margin-top: 64px;
    display: flex;
}
.tab {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
    color: #262626;
    padding: 25px 60px;
    transform: translateY(1px);
    position: relative;
    z-index: 2;
    border: 1px solid #00000000;
}
.tab-active {
    border: 1px solid #dbdbdb;
    border-bottom: none;
    background: #ffffff;
}
.tab-content-wrapper {
    border: 1px solid #dbdbdb;
    padding: 80px 30px;
}
.tabs-content {
    display: none;
}
.tabs-content-active {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    .img {
        animation: opacityTransform 0.5s ease-in-out;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
    .text {
        animation: opacityTransform 0.5s ease-in-out;
        h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            color: #e67f31;
        }
        p {
            font-weight: 300;
            font-size: 18px;
            line-height: 150%;
            color: #262626;
            margin-top: 30px;
        }
    }
}

@keyframes opacityTransform {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
