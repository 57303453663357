@media (max-width: 1024px) {
    .promo .container h1 span {
        font-size: 40px;
        line-height: 150%;
    }
    .nav {
        position: fixed;
        width: 100%;
        height: calc(100% - 80px);
        bottom: 0;
        left: 0;
        z-index: 2000;
        background: #262626;
        padding: 60px 20px;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        &.active {
            transform: translateX(0);
        }
        ul {
            flex-direction: column;
            gap: 38px;
            a {
                font-size: 18px;
                color: #fff;
            }
        }
    }
    .inst {
        margin-left: auto;
        margin-right: 30px;
    }
    .logo {
        height: 29.23px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
    .text-img__wrapper {
        flex-direction: column;
        gap: 20px;
    }
    .text-img__wrapper .text p {
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 20px;
    }
    .text-img {
        margin-top: 40px;
    }
    .text-img__wrapper .img {
        width: 100%;
    }
    .title {
        font-size: 24px;
    }
    .advantages {
        margin-top: 60px;
    }
    .advantages__text {
        font-size: 16px;
        margin-top: 20px;
    }
    .advantages__wrapper {
        grid-template-columns: 48% 48%;
        gap: 4%;
        margin-top: 40px;
    }
    .advantages-item .text {
        font-size: 14px;
        word-break: break-word;
    }
    .advantages-item {
        max-width: 50vw;
    }
    .text-img--v2 {
        padding: 40px 0;
    }
    .btn {
        width: 100%;
    }
    .text-img__wrapper .img {
        margin-top: 20px;
    }
    .about {
        margin-top: 60px;
    }
    .about .about__wrapper {
        margin-top: 20px;
        .img {
            margin-top: 0;
        }
    }
    .about .about__wrapper .text p {
        font-size: 16px;
    }
    .about .about__wrapper h4 {
        font-size: 16px;
    }
    .text-img__wrapper--reverse {
        flex-direction: column-reverse;
    }
    .advantages {
        margin-top: 60px;
    }
    .tab {
        padding: 10px 5px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tabs-content-active {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .tab-content-wrapper {
        padding: 20px 10px;
    }
    .tabs-content-active .text h3 {
        font-size: 18px;
    }
    .tabs-content-active .text p {
        margin-top: 20px;
    }
    .markers-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0;
        row-gap: 30px;
        column-gap: 4%;
        .item {
            width: 30%;
            p {
                font-size: 15px;
            }
        }
    }
    .map {
        .text-img__wrapper {
            gap: 0;
        }
    }
    .bottom-form {
        margin-top: 20px;
    }
    .contact {
        padding-bottom: 40px;
    }
    .footer .container {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }
    .footer iframe {
        width: 100%;
    }
    .footer {
        padding: 30px 0 40px;
    }
    .burger {
        width: 36px;
        height: 36px;
        background-image: url("../img/burg.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
        transition: background-image 0.3s ease-in-out;
        &.active {
            background-image: url("../img/Close.svg");
        }
    }
}
