.contact {
    position: relative;
    padding: 40px 0 80px;
    .title {
        color: #ffffff;
        text-align: center;
    }
}

.contact__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.bottom-form {
    max-width: 605px;
    margin: 40px auto 0;
}

.form__input {
    display: block;
    margin-bottom: 30px;
    input {
        background: #ffffff;
        border-radius: 10px;
        height: 60px;
        width: 100%;
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        color: #262626;
        padding: 0 30px;
        border: none;
        &::placeholder {
            color: #262626;
        }
    }
}

.form__textarea {
    textarea {
        background: #ffffff;
        border-radius: 10px;
        height: 200px;
        width: 100%;
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        color: #262626;
        padding: 16px 30px;
        border: none;
        resize: none;
        &::placeholder {
            color: #262626;
        }
    }
}
.form__btn {
    margin: 40px auto 0;
}

.bottom-form__done {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    display: none;
}

.just-validate-error-label {
    font-size: 18px;
    font-weight: 700;
}
