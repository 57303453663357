.promo {
    position: relative;
    .container {
        min-height: 100vh;
        flex-direction: column;
        display: flex;
        justify-content: center;
        h1 {
            span {
                font-weight: 700;
                font-size: 80px;
                line-height: 150%;
                text-align: center;
                color: #ffffff;
                display: block;
            }
        }
    }
}
.promo__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
