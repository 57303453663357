.about {
    margin-top: 100px;
    .about__wrapper {
        margin-top: 47px;
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        color: #262626;
        > .text {
            font-weight: 300;
            font-size: 18px;
            line-height: 150%;
            color: #262626;
            max-width: 605px;
        }
        .text {
            p {
                font-weight: 300;
                font-size: 18px;
                line-height: 150%;
                color: #262626;
            }
        }
        h4 {
            font-weight: 700;
        }
        li {
            margin-left: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                left: -18px;
                top: 12px;
                background: #262626;
            }
        }
    }
}

.about__top {
    display: flex;
}

.text-img__wrapper--reverse {
    margin-top: 40px;
}
