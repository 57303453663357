:root {
  --color-black: #000;
  --color-white: #fff;
}

/* @include font-face("GothamPro", "GothamPro", 400, normal); */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

ul li {
  list-style: none;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

input:not([type=checkbox]):not([type=radio]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

* {
  font-family: "Roboto", sans-serif;
}

body.scroll-hide {
  overflow: hidden;
}

section {
  background: #ffffff;
  z-index: 1;
}

.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #1c75bb;
}

.text-center {
  text-align: center;
}

.container {
  max-width: 1090px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.btn {
  background: #e67f31;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 394px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .btn:hover {
    background: #d56c1b;
  }
}

.header {
  background: #ffffff;
  padding: 22px 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 150px;
}
.logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.nav {
  margin-left: auto;
  margin-right: 70px;
}
.nav ul {
  display: flex;
  gap: 90px;
}
.nav ul a {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-transform: uppercase;
  color: #262626;
}
@media (any-hover: hover) {
  .nav ul a:hover {
    text-decoration: underline;
  }
}

.inst img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.advantages {
  margin-top: 100px;
}

.advantages__text {
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #262626;
  margin: 30px auto 0 auto;
  max-width: 816px;
}

.advantages__wrapper {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 70px;
}

.advantages-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.advantages-item .icon {
  background: #1c75bb;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.advantages-item .icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.advantages-item .text {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #262626;
}

.tab-link-wrapper {
  margin-top: 64px;
  display: flex;
}

.tab {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #262626;
  padding: 25px 60px;
  transform: translateY(1px);
  position: relative;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0);
}

.tab-active {
  border: 1px solid #dbdbdb;
  border-bottom: none;
  background: #ffffff;
}

.tab-content-wrapper {
  border: 1px solid #dbdbdb;
  padding: 80px 30px;
}

.tabs-content {
  display: none;
}

.tabs-content-active {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.tabs-content-active .img {
  animation: opacityTransform 0.5s ease-in-out;
}
.tabs-content-active .img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.tabs-content-active .text {
  animation: opacityTransform 0.5s ease-in-out;
}
.tabs-content-active .text h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #e67f31;
}
.tabs-content-active .text p {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #262626;
  margin-top: 30px;
}

@keyframes opacityTransform {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.about {
  margin-top: 100px;
}
.about .about__wrapper {
  margin-top: 47px;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #262626;
}
.about .about__wrapper > .text {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #262626;
  max-width: 605px;
}
.about .about__wrapper .text p {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #262626;
}
.about .about__wrapper h4 {
  font-weight: 700;
}
.about .about__wrapper li {
  margin-left: 30px;
  position: relative;
}
.about .about__wrapper li::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  left: -18px;
  top: 12px;
  background: #262626;
}

.about__top {
  display: flex;
}

.text-img__wrapper--reverse {
  margin-top: 40px;
}

.promo {
  position: relative;
}
.promo .container {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.promo .container h1 span {
  font-weight: 700;
  font-size: 80px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  display: block;
}

.promo__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.text-img {
  margin-top: 60px;
  position: relative;
}
.text-img .title {
  margin-bottom: 30px;
}

.text-img__wrapper {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}
.text-img__wrapper .text {
  max-width: 635px;
}
.text-img__wrapper .text p {
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #262626;
  margin-bottom: 30px;
}
.text-img__wrapper .img {
  max-width: 400px;
  width: 400px;
}
.text-img__wrapper .img img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.text-img__wrapper .img-map {
  background: #1c75bb;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 314px;
}
.text-img__wrapper .img-map img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: unset;
}

.text-img--v2 {
  padding: 80px 0;
  margin-top: 100px;
}
.text-img--v2 .text {
  max-width: 499px;
}
.text-img--v2 .text p {
  text-align: left;
}
.text-img--v2 .text b {
  font-weight: 700;
}
.text-img--v2 .img {
  max-width: 499px;
}

.text-img__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.map .text p {
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: #262626;
}

.markers-wrapper {
  margin: 30px auto 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: 816px;
  gap: 40px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}
.item p {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-transform: uppercase;
  color: #e67f31;
}

.contact {
  position: relative;
  padding: 40px 0 80px;
}
.contact .title {
  color: #ffffff;
  text-align: center;
}

.contact__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.bottom-form {
  max-width: 605px;
  margin: 40px auto 0;
}

.form__input {
  display: block;
  margin-bottom: 30px;
}
.form__input input {
  background: #ffffff;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #262626;
  padding: 0 30px;
  border: none;
}
.form__input input::placeholder {
  color: #262626;
}

.form__textarea textarea {
  background: #ffffff;
  border-radius: 10px;
  height: 200px;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #262626;
  padding: 16px 30px;
  border: none;
  resize: none;
}
.form__textarea textarea::placeholder {
  color: #262626;
}

.form__btn {
  margin: 40px auto 0;
}

.bottom-form__done {
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #fff;
  margin-top: 20px;
  display: none;
}

.just-validate-error-label {
  font-size: 18px;
  font-weight: 700;
}

.footer {
  background: #262626;
  padding: 40px 0 60px;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.footer iframe {
  width: 394px;
  height: 174px;
  border: none;
}

.footer__left {
  max-width: 287px;
}
.footer__left h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 26px;
}
.footer__left p {
  font-weight: 300;
  font-size: 18px;
  line-height: 250%;
  color: #ffffff;
}

.phone {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 20px;
  width: fit-content;
  margin-top: 20px;
}
@media (any-hover: hover) {
  .phone:hover {
    text-decoration: underline;
  }
}
.phone::before {
  content: "";
  background-image: url("../../img/Phone.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
}

.footer__right {
  display: flex;
  flex-direction: column;
}
.footer__right a {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  display: block;
  margin-top: 20px;
}
@media (any-hover: hover) {
  .footer__right a:hover {
    text-decoration: underline;
  }
}
.footer__right a:first-child {
  margin-top: auto;
}

@media (max-width: 1024px) {
  .promo .container h1 span {
    font-size: 40px;
    line-height: 150%;
  }
  .nav {
    position: fixed;
    width: 100%;
    height: calc(100% - 80px);
    bottom: 0;
    left: 0;
    z-index: 2000;
    background: #262626;
    padding: 60px 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  .nav.active {
    transform: translateX(0);
  }
  .nav ul {
    flex-direction: column;
    gap: 38px;
  }
  .nav ul a {
    font-size: 18px;
    color: #fff;
  }
  .inst {
    margin-left: auto;
    margin-right: 30px;
  }
  .logo {
    height: 29.23px;
  }
  .logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .text-img__wrapper {
    flex-direction: column;
    gap: 20px;
  }
  .text-img__wrapper .text p {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 20px;
  }
  .text-img {
    margin-top: 40px;
  }
  .text-img__wrapper .img {
    width: 100%;
  }
  .title {
    font-size: 24px;
  }
  .advantages {
    margin-top: 60px;
  }
  .advantages__text {
    font-size: 16px;
    margin-top: 20px;
  }
  .advantages__wrapper {
    grid-template-columns: 48% 48%;
    gap: 4%;
    margin-top: 40px;
  }
  .advantages-item .text {
    font-size: 14px;
    word-break: break-word;
  }
  .advantages-item {
    max-width: 50vw;
  }
  .text-img--v2 {
    padding: 40px 0;
  }
  .btn {
    width: 100%;
  }
  .text-img__wrapper .img {
    margin-top: 20px;
  }
  .about {
    margin-top: 60px;
  }
  .about .about__wrapper {
    margin-top: 20px;
  }
  .about .about__wrapper .img {
    margin-top: 0;
  }
  .about .about__wrapper .text p {
    font-size: 16px;
  }
  .about .about__wrapper h4 {
    font-size: 16px;
  }
  .text-img__wrapper--reverse {
    flex-direction: column-reverse;
  }
  .advantages {
    margin-top: 60px;
  }
  .tab {
    padding: 10px 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabs-content-active {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .tab-content-wrapper {
    padding: 20px 10px;
  }
  .tabs-content-active .text h3 {
    font-size: 18px;
  }
  .tabs-content-active .text p {
    margin-top: 20px;
  }
  .markers-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
    row-gap: 30px;
    column-gap: 4%;
  }
  .markers-wrapper .item {
    width: 30%;
  }
  .markers-wrapper .item p {
    font-size: 15px;
  }
  .map .text-img__wrapper {
    gap: 0;
  }
  .bottom-form {
    margin-top: 20px;
  }
  .contact {
    padding-bottom: 40px;
  }
  .footer .container {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
  .footer iframe {
    width: 100%;
  }
  .footer {
    padding: 30px 0 40px;
  }
  .burger {
    width: 36px;
    height: 36px;
    background-image: url("../img/burg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    transition: background-image 0.3s ease-in-out;
  }
  .burger.active {
    background-image: url("../img/Close.svg");
  }
}